.aboutDis {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-y: hidden !important;
    padding-top: 100px;
    gap: 20px;
}

.animationDiv {
    width: 50%;
}
.aboutDis img {
    width: 100%;
    height: 650px;
    object-fit: cover;
}
.aboutDis p,
.aboutDis h2 {
    margin: 0px;
}
.aboutDis h2 {
    font-size: 50px;
    font-weight: 300;
}
.aboutDis p {
    line-height: normal;

    font-size: 18px;
    font-weight: 300 !important;
}
.aboutDis p {
    padding-bottom: 20px;
}
.aboutDis span {
    font-weight: 600;
    padding-bottom: 20px;
}
.aboutDisRev {
    flex-direction: row-reverse;
}

.aboutMe a {
    background-color: #b9b1a7;
    padding: 15px 40px;
    width: fit-content;
    display: block;
    cursor: pointer;
    color: white;
}
.aboutContent {
    display: flex;
    flex-direction: column;
    text-align: left;
}
@media (max-width: 768px) {
    .aboutDis {
        flex-direction: column;
    }
    .animationDiv {
        width: 100%;
    }
}
