/* .collageDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0px 40px;
    grid-auto-rows: 1fr;
    gap: 40px;
} */
.main {
    min-height: 100vh;
    /* padding: 1.25rem; */
}

.container {
    padding: 1.25rem;
}

/* Set responsive columns */
.collageDis {
    column-count: 2; /* Two columns on mobile */
    column-gap: 0.75rem;
    /* padding-top: 50px; */
}

@media (min-width: 768px) {
    .collageDis {
        column-count: 3; /* Three columns on tablet and above */
        column-gap: 1.5rem;
    }
}

@media (min-width: 1024px) {
    .collageDis {
        column-count: 3;
        column-gap: 2rem;
    }
}

.imageWrapper {
    position: relative;
    margin-top: 1rem;
}

.imageWrapper:first-child {
    margin-top: 0;
}

@media (min-width: 1024px) {
    .imageWrapper:not(:first-child) {
        margin-top: 2rem;
    }
}

.collageImg {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease-in-out;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease-in-out;
}

.imageWrapper:hover .overlay {
    background-color: rgba(0, 0, 0, 0.2);
}

/* Optional: Staggered positioning for collage effect */

.collageFilter h2 {
    font-weight: 300;
    margin: 0px;
}
.collageFilter {
    padding-top: 100px;
    padding-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    /* width: 1400px; */

    margin: auto;
}
.collageFilter p {
    cursor: pointer;
    color: #b9b1a7;
    transition: 0.3s;
    padding: 4px 0px;
}
.collageFilter p:hover {
    color: black;
}

.dropdown {
    /* Styles for the select dropdown */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    color: black !important;
    cursor: pointer;
}

.dropdown:focus {
    outline: none;
    border-color: #b9b1a7; /* Change border color on focus */
}
.collageDis img {
    display: block;
}
@media (max-width: 768px) {
    .collageDis {
        grid-template-columns: repeat(2, 1fr);
        /* padding: 0px 20px; */
        gap: 20px;
    }
    .container {
        padding: 0px;
    }
    .collageFilter {
        width: 90%;
        justify-content: center;
    }
    .collageFilter {
        flex-direction: column;
        align-items: unset;
    }
}
