.main {
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 40px;
    /* padding: 1.25rem; */
}

.container {
    padding: 1.25rem;
}

/* Set responsive columns */
.grid {
    column-count: 2; /* Two columns on mobile */
    column-gap: 0.75rem;
    padding-top: 50px;
}

@media (min-width: 768px) {
    .grid {
        column-count: 3; /* Three columns on tablet and above */
        column-gap: 1.5rem;
    }
}

@media (min-width: 1024px) {
    .grid {
        column-count: 3;
        column-gap: 2rem;
    }
}

.imageWrapper {
    position: relative;
    margin-top: 1rem;
}

.imageWrapper:first-child {
    margin-top: 0;
}

@media (min-width: 1024px) {
    .imageWrapper:not(:first-child) {
        margin-top: 2rem;
    }
}

.image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease-in-out;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease-in-out;
}

.imageWrapper:hover .overlay {
    background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
    .container {
        padding: 0px;
    }
    .grid {
        padding-top: 70px;
    }
}
