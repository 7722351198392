.footer {
    background-color: white;
    padding: 40px 0px;
    width: 95%;
    margin: auto;
    border-top: 1px solid rgb(103, 103, 104, 0.3);
    margin-top: 50px;
}
.footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.footer p {
    font-size: 12px;
    color: #676768;
    text-align: left;
}

.footerItem {
    display: flex;
    align-items: center;
    gap: 20px;
    color: black;
    padding-bottom: 20px;
}
.footerItem a {
    color: black;
    font-weight: 100;
    font-size: 18px;
    transition: 0.3s;
}
.footerItem a:hover {
    color: #b9b1a7;
}
.footerIcons {
    display: flex;
    gap: 10px;
    align-items: center;
}
.footerIcons img {
    width: 17px;
    height: 17px;
    opacity: 0.4;
    transition: 0.3s;
    display: block;
}
.footerIcons img:hover {
    opacity: 1;
}
@media (max-width: 800px) {
    .footer {
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        align-items: center;
    }
    .footerItem {
        padding-bottom: 10px;
    }
}
