@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    /* cursor: none; */
    font-family: 'Catamaran', sans-serif;
}
#dark {
    background-color: black;
}
:root {
    --main: black;
    --dark-main: #b9b1a7;
    --light-main: hsl(192, 100%, 42%, 0.07);
    --white: #ffffff;
    --black: #0000;
    --radius-image: 20px;
    --radius-input: 5px;
    --border-color: #efefef;
}
.container {
    width: 1200px;
    /* padding-left: 100px;
  padding-right: 100px; */
    margin: auto;
    text-align: center;
}
.sub-container {
    width: 900px;
    margin: auto;
    text-align: center;
}
li,
textarea {
    list-style: none;
    font-family: 'GT Walsheim' !important;
}
a {
    text-decoration: none;
}
p {
    /* text-align: left; */
    font-weight: 500 !important;
}
.d-flex {
    display: flex;
}
.w-100 {
    width: 100%;
}
.w-49 {
    width: 49%;
}
.w-30 {
    width: 30%;
}
.w-60 {
    width: 60%;
}
.w-25 {
    width: 25%;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-50 {
    padding-top: 50px;
}
.pb-50 {
    padding-bottom: 50px;
}
.ptb-20 {
    padding: 20px 0px;
}
.pb-20 {
    padding-bottom: 20px;
}
.title {
    font-size: 64px;
}
.m-auto {
    margin: auto;
}
.subTitle {
    font-size: 30px;
}
.smallTitle {
    font-size: 32px;
    text-align: left;
}
.space-between {
    justify-content: space-between;
    align-items: center;
}
.stepsTitle {
    font-size: 35px;
    padding-bottom: 40px;
    height: 100px;
}
.hamburger-react {
    color: white;
}
.bannerSwiper .swiper-pagination {
    color: white !important;
    font-size: 18px !important;
    padding-bottom: 20px;
}
.bannerSwiper .swiper-button-prev {
    bottom: 15px !important;
    top: unset;
    color: white;
    left: 45% !important;
    z-index: 99999;
}
.bannerSwiper .swiper-button-prev:after {
    font-size: 15px;
}
.bannerSwiper .swiper-button-next {
    bottom: 15px !important;
    top: unset;
    color: white;
    right: 45% !important;
    z-index: 99999;
}
.bannerSwiper .swiper-button-next:after {
    font-size: 15px;
}
.no-scroll .hambColor div,
.no-scroll .itemsColor a {
    color: white !important;
}
.no-scroll .imageColor img {
    filter: invert(100%);
}

.emailSticky {
    bottom: 85px;
    flex-direction: column;
    right: 20px;

    position: fixed;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 99;
}
.lbvXTF {
    z-index: 1 !important;
}
.emailSticky a {
    display: flex;
    background-color: #b9b1a7;

    border-radius: 100%;
    box-shadow: 0 4px 4px 0 #00000040;
    height: 64px;
    width: 64px;
    justify-content: center;
    margin-bottom: 10px;
}
.emailSticky img {
    height: 30px;
    margin: auto;
}
.videoIframe {
    padding: 50px 20px;
    /* width: 100%; */
}
.videoIframe iframe {
    width: 90%;
    height: 600px;

    margin-bottom: 50px;
}
.videoIframe .swiper-button-prev {
    color: black;
    top: 97.5%;
    left: 40%;
    width: 40px;
    height: 40px;
    z-index: 999999999999999;
}
.videoIframe .swiper-button-prev::after {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.videoIframe .swiper-button-next {
    color: black;
    top: 97.5%;
    right: 40%;
    width: 40px;
    height: 40px;
    z-index: 999;
}
.videoIframe .swiper-button-next::after {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

@media only screen and (min-width: 2000px) {
    .videoIframe iframe {
        width: 90%;
        height: 1200px;

        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 1400px) {
    .container {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .videoIframe .swiper-button-prev {
        left: 30%;
    }
    .videoIframe .swiper-button-next {
        right: 30%;
    }
    .subTitle {
        font-size: 30px;
    }
    .videoIframe iframe {
        width: 100%;
    }
}
