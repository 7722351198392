.swiperWrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative;
}
.sliderPosition img {
    height: 400px;
    /* width: 320px; */
    object-fit: cover;
    object-position: center;
    /* border-radius: 10px; */
}
.sliderHeader {
    text-align: center;
    padding: 50px 0px;
}
.sliderHeader span {
    color: var(--dark-main);
}
@media (max-width: 800px) {
    .sliderPosition img {
        margin: auto;
        display: flex;
        /* width: 300px; */
    }
    .sliderHeader {
        padding: 50px 20px;
    }
    .sliderHeader h2 {
        font-size: 20px;
    }
    .sliderHeader p {
        padding: 10px 0px;
    }
}
