.contactBtn {
    padding: 50px 20px;
    text-align: left;
}
.contactBtn {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contactDis h2 {
    text-align: center;
    font-size: 40px;
    color: #676769;
}
.contactDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    padding-top: 100px;
    padding-bottom: 50px;
}
.contactDis img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: top;
}
.contactBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.contactBtn div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.contactBtn img {
    width: 30px;
    height: 30px;
    display: block;
}
.contactBtn a {
    background-color: #b9b1a7;
    padding: 12px 40px;
    width: fit-content;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}
.contactBtn a:hover {
    background-color: black;
}
@media (max-width: 800px) {
    .contactDis {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        gap: 10px;
    }
    .contactBtn {
        padding: 0px;
    }
}
