.bannerBck {
    background-color: black;
    padding: 100px 0px;
    position: relative;
    overflow: hidden; /* Ensures content inside doesn't overflow */
}

.bannerDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 0px 40px;
}
.animationBanner {
    position: relative;
    display: flex;
}
.bannerDis a {
    position: relative;
}

.bannerDis img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transition: transform 2s ease-out;
}
.bannerDis p {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    text-align: center;
    z-index: 999;
}
.bannerImage::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); /* 0.1 opacity black overlay */
    pointer-events: none; /* Ensure clicks go through overlay to image */
}
.animationBanner {
    overflow: hidden;
}
.animationBanner:hover img {
    transform: scale(1.1);
}
.overlay {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); /* 0.1 opacity black overlay */
    z-index: 99;
}
@media (max-width: 800px) {
    .bannerDis {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
        column-gap: 10px;
        padding: 0px 20px;
    }
    .bannerDis p {
        font-size: 16px;
        text-align: center;
    }
}
