.portfolioDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 40px;
    gap: 40px;
}
.portfolioDis img {
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    display: block;
}
.portfolioDisImg {
    height: 650px;
}
.portfolioHeading {
    padding-top: 100px;
    padding-bottom: 50px;
    text-align: center;
}
.portfolioHeading p {
    width: 60%;
    margin: auto;
}

@media (max-width: 800px) {
    .portfolioDisImg {
        height: auto;
    }
    .portfolioDis {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px 20px;
        gap: 20px;
    }
    .portfolioHeading p {
        width: 100%;
    }
    .portfolioHeading {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
    }
}
