.category {
    position: relative;
    cursor: pointer;
    overflow: hidden; /* Add this line to hide overflow */
}
.category div {
}
.overlay {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* 0.1 opacity black overlay */
    z-index: 999;
}

.category img {
    filter: grayscale(100%);
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transition: transform 2s ease-out;
}

.withColor img {
    filter: unset;
}
.withColor {
    height: 550px;
}
.category h2 {
    color: white;
    position: absolute;
    font-size: 32px;
    font-weight: 400;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category:hover img {
    transform: scale(1.1);
}
.changePos {
    height: 650px;
}
.changePos img {
    object-position: bottom;
}
@media (max-width: 800px) {
    .changePos {
        height: 300px;
    }
    .withColor {
        height: 250px;
    }
    .category h2 {
        font-size: 18px;
        text-align: center;
    }
}
