/* Header.module.css */

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 0px;
    transition: background-color 0.3s ease, transform 0.3s ease-in-out;
    z-index: 1000;
    background-color: transparent; /* Transparent by default */
    background-color: white;
}

.navDis {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 40px; */
}

.navbarLogo {
    font-size: 24px;
    font-weight: bold;
}

.navbarMenu {
    display: flex;
    align-items: center;
    position: relative;
}
.navbarMenu div {
    color: black;
}
.navbarScrolled div {
    color: black;
}
.blob {
    display: none;
}
.navbarItems a {
    margin-left: 20px;
    text-decoration: none;
    color: black;
    font-size: 18px;
    transition: 0.3s;
    font-weight: 600;
}
.navbarItems a:hover {
    color: #b9b1a7 !important;
}
.whiteItems a {
    color: black;
}

.navbarScrolled {
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

/* Other styles remain the same */
.navbarItems a {
    color: black;
    transition: color 0.3s;
}

/* .navbarScrolled a {
    color: white !important;
} */

.navbar a:hover {
    color: #555;
}

/* Mobile styles */
.hamburger {
    display: none;
}

.navbarLogo img {
    width: 70px;
    display: block;
    /* filter: invert(100%); */
}
.navbarHidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.navbar {
    transition: transform 0.3s ease-in-out;
}
.whiteLogo img {
    filter: unset;
}

/* .navbarScrolled img {
    filter: invert(100%);
} */
.hambColor div {
    color: black;
}
.navbarScrolled .hambColor div {
    color: black;
}
.mobileItem img {
    display: none;
}
.iconDis {
    display: none;
}
.showMenu a {
    color: white;
}
.changeLogo img {
    filter: invert(100%);
}
.changeLogo .hamburger div {
    color: white;
}
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
    .whiteLogo a {
        color: white !important;
    }
    .navDis {
        padding: 0px 20px;
        z-index: 999;
        position: relative;
    }

    .navbarItems {
        visibility: hidden;
        opacity: 0;
        width: 100%;
        text-align: center;
        background-color: black;
        position: fixed;
        top: 0;
        left: -100%;
        height: 100%;
        transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out,
            visibility 0s 0.5s; /* Delay visibility change to match the transition duration */
        margin: 0;
        z-index: 2;
    }
    .iconDis {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        position: absolute;
        bottom: 10px;
        left: 0px;
        right: 0px;
    }

    .iconDis img {
        width: 33px !important;
        height: 33px !important;
    }
    .navbarItems::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black; /* Black color overlay */
        opacity: 0.7; /* Adjust the opacity as needed */
        z-index: 1; /* Ensure it is on top */
        pointer-events: none; /* Make sure it doesn't interfere with other elements */
    }
    .itemIcons img {
        display: none;
    }
    .navbarItems.showMenu {
        left: 0;
        visibility: visible;
        opacity: 1;
        transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Override visibility transition to occur immediately */
    }

    .hamburger,
    .navbarLogo {
        z-index: 999;
    }
    .navbarLogo img {
        width: 60px;
    }
    .navbar {
        padding: 15px 0px;
    }
    .navbarItems a {
        margin: 15px 0;
        font-size: 20px;
        z-index: 9999999;
    }

    /* Ensure scrolling is disabled when menu is open */
    body.no-scroll {
        overflow: hidden;
    }

    .blob {
        display: block;
        position: absolute;
        width: 550px;
        height: 550px;
        left: -10px;

        bottom: -45%;
        z-index: 99999;
    }

    .navbarItems {
        display: flex;
        /* align-items: center; */
        /* margin-left: 20px; */
    }
    .mobileItem {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 100px;
        width: 90%;
        margin: 0px auto;
    }
    .mobileItem img {
        width: 15px;
        height: 15px;
        filter: unset !important;
        display: block;
    }
    .mobileItem a {
        /* border-bottom: 1px solid #ffffff79; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobilebck {
        width: 100% !important;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        right: 0;
        height: 100% !important;
    }
}
